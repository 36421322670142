var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.alert.show)?_c('Alert',{attrs:{"text":_vm.alert.text}}):_vm._e(),_c('v-container',{staticClass:"responsive-container"},[_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"line-chart-container1"},[(_vm.chartData1.show && _vm.params.showLogo)?_c('img',{attrs:{"src":"/assets/img/x-radar.svg","alt":"logo","width":"190","height":"190"}}):_vm._e(),(_vm.chartData1.show)?_c('LineChart',{attrs:{"showXTicks":"","padding":{
            left: 5,
            right: 40,
            top: 20,
            bottom: 0,
          },"data":_vm.chartData1,"chartId":_vm.chartData1.id},on:{"updateDataAnnotations":_vm.updateDataAnnotations}}):_vm._e()],1),_c('div',{staticClass:"line-chart-container2"},[(_vm.chartData2.show && _vm.params.showLogo)?_c('img',{attrs:{"src":"/assets/img/x-radar.svg","alt":"logo","width":"190","height":"190"}}):_vm._e(),(_vm.chartData2.show)?_c('LineChart',{staticClass:"chart2",attrs:{"showXTicks":"","padding":{
            left: 5,
            right: 40,
            top: 40,
            bottom: 20,
          },"data":_vm.chartData2,"chartId":_vm.chartData2.id,"isDemoUser":_vm.isDemoUser},on:{"updateDataAnnotations":_vm.updateDataAnnotations}}):_vm._e()],1),(_vm.validate)?_c('v-btn',{staticClass:"btn",attrs:{"color":_vm.color,"small":""},on:{"click":_vm.downloadDoc}},[_vm._v("Download")]):_vm._e(),(_vm.chartData.length)?_c('v-btn',{staticClass:"ml-5 btn",attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":_vm.color,"small":""},on:{"click":function () { return _vm.handleChangeDialog(_vm.objJsonB64, _vm.chartData2.data); }}},[_vm._v(" Grafik einbinden "),_c('v-icon',{staticStyle:{"color":"#fff"},attrs:{"small":""}},[_vm._v("mdi-share")])],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }