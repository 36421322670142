





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import LineChart from '@/components/fmh_components/common/LineChart/LineChart.vue';
import FormSpecialChart from '@/views/ChartGenerator/tabs/FormSpecialChart/FormSpecialChart.vue';

import { BASE_BLACK_COLOR } from '@/utils/constants';
import { IChartData, IUser, SpecialChartGeneratorParams } from '@/interfaces';

@Component({
  components: { LineChart, FormSpecialChart },
  computed: {
    ...mapGetters({
      loading: 'interestCharts/loading',
      user: 'auth/getUser',
    }),
  },
})
export default class SpecialChart extends Vue {
  @Prop() handleChangeDialog!: Function;
  @Prop() fetchSpecialChartData!: Function;
  @Prop() chartData!: IChartData;
  @Prop() data?: object[];
  @Prop() objJsonB64!: String;
  @Prop() params!: SpecialChartGeneratorParams;

  user?: IUser;
  color = BASE_BLACK_COLOR;

  get isDemoUser() {
    return !!this.user?.isDemoUser;
  }
  get validate() {
    return this.chartData.data.length;
  }

  get description() {
    if (this.params?.selectedSpecialValue === 'rbGrafikAuswahl5') {
      const stringID = this.params?.selectedSpecialSubValue.join('_');
      return this.$t(
        `chartGenerator.specialChartHousePriceDescription.${stringID}`
      );
    }
    return '';
  }

  downloadDoc() {
    //eslint-disable-next-line
    const HtmlHead = "<html lang='de'><head><meta charset='utf-8'><title>Doc</title></head><body>";
    const EndHtml = '</body></html>';
    const canvas = document.getElementById('line-chart-3');
    const dataURL = (canvas as HTMLCanvasElement)?.toDataURL() || '';
    //eslint-disable-next-line
    const image = "<div id='export'><img alt='canvas image' class='hide' src='" + dataURL +"'/></div>";
    const html = HtmlHead + image + EndHtml;
    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    const url =
      'data:application/msword;charset=utf-8,' + encodeURIComponent(html);

    // Specify file name
    const filename = 'document.doc';

    // Create download link element
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    if ((navigator as any).msSaveOrOpenBlob) {
      (navigator as any).msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;
      // Setting the file name
      downloadLink.download = filename;
      //triggering the function
      downloadLink.click();
    }
    document.body.removeChild(downloadLink);
  }
  mounted() {
    this.fetchSpecialChartData();
  }
}
