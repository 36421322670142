




























































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import Loader from '@/components/fmh_components/common/Loader/Loader.vue';

import { BASE_BLACK_COLOR } from '@/utils/constants';

@Component({
  components: { Loader },
})
export default class DatePicker extends Vue {
  @Prop() isLoading: Boolean | undefined;
  @Prop() date!: string;
  @Prop() field!: string;
  @Prop() min!: string;

  [x: string]: any;
  color = BASE_BLACK_COLOR;
  menu: Boolean = false;
  dates: Array<string> = [];
  max = `${new Date().getFullYear()}-12-31`;
  error = '';

  @Watch('error') resetError(val) {
    if (val) {
      setTimeout(() => {
        this.error = '';
      }, 4000);
    }
  }

  get label() {
    return this.$i18n.locale === 'de' ? 'MM/JJJJ' : 'MM/YYYY';
  }

  get model() {
    return this.date;
  }
  set model(val) {
    this.$emit('changedDate', { [this.field]: val });
  }

  handleChangeKeyDown(e) {
    if (
      e.key !== 'Tab' &&
      e.key !== 'Backspace' &&
      e.key !== 'ArrowRight' &&
      e.key !== 'ArrowLeft' &&
      e.key !== '/' &&
      !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)
    ) {
      e.preventDefault();
    }
  }
  handleDateChanged(e) {
    const error =
      this.$i18n.locale === 'de'
        ? 'Bitte geben Sie das Datum im Format MM/JJJJ ein'
        : 'Please enter the date in MM/YYYY format';

    if (e.length === 7) {
      const arr = e.split('/').reverse();
      if (arr[0].length === 4 && arr[1].length === 2) {
        const minYear = this.min.split('-')[0];
        const maxYear = this.max.split('-')[0];
        if (+arr[0] >= +minYear && +arr[0] <= +maxYear) {
          const date = arr.join('-');
          this.$emit('changedDate', { [this.field]: date });
        } else {
          this.error =
            this.$i18n.locale === 'de'
              ? `Muss mind. Jahr: ${minYear}, max: ${maxYear}`
              : `Should be min. year: ${minYear}, max: ${maxYear}`;
        }
      } else {
        this.error = error;
      }
    }
    if (e.length > 7) {
      this.error = error;
    }
  }
  save(date) {
    if (this.range) {
      if (date[0] === date[1]) return;
      (this.$refs.menu as any).save(date);
    }
    (this.$refs.menu as any).save(date);
  }
}
