



















































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, MutationMethod } from 'vuex';

import SnackBar from '@/components/fmh_components/common/SnackBar/SnackBar.vue';
import BaseChart from '@/views/ChartGenerator/tabs/BaseChart.vue';
import SpecialChart from '@/views/ChartGenerator/tabs/SpecialChart.vue';
import Loader from '@/components/fmh_components/common/Loader/Loader.vue';
import FormBaseChart from '@/views/ChartGenerator/tabs/FormBaseChart/FormBaseChart.vue';
import FormSpecialChart from '@/views/ChartGenerator/tabs/FormSpecialChart/FormSpecialChart.vue';
import CopyIframeDialog from '../../../wettbewerbsvergleich/src/components/common/CopyIframeDialog/CopyIframeDialog.vue';

import {
  BASE_BLACK_COLOR,
  HOST,
  MONTHS,
  SELECTED_COLOR,
  SPECIAL_CHART_FORM_SUB_OPTIONS,
} from '@/utils/constants';

import {
  IBaseChart,
  IChartData,
  ChartGeneratorParams,
  ResultSeries,
} from '@/interfaces';

interface RequiredPayload {
  data: object[];
}

@Component({
  components: {
    BaseChart,
    SnackBar,
    SpecialChart,
    Loader,
    FormBaseChart,
    FormSpecialChart,
    CopyIframeDialog,
  },
  computed: {
    ...mapGetters({
      snackbarData: 'reports/getSnackbarData',
      loading: 'interestCharts/loading',
    }),
  },
  methods: {
    ...mapActions({
      saveZinsChartData: 'interestCharts/saveChartData',
      fetchInterestChartData: 'interestCharts/fetchInterestChartData',
    }),
    ...mapMutations({
      setLoading: 'interestCharts/loading',
      setSnackBarData: 'reports/setSnackBarData',
      setBaseChartData: 'interestCharts/setBaseChartData',
    }),
  },
})
export default class ChartGenerator extends Vue {
  @Prop() drawerApp!: Boolean;

  tab = 'tab-1';
  color = BASE_BLACK_COLOR;
  selectedColor = SELECTED_COLOR;
  months = MONTHS;
  dialog = false;
  isCopied = false;
  iframe = '';
  drawer = true;
  //special chart variables
  isShowLogo = true;
  needUpdate = false;
  pickerDates = {
    rbGrafikAuswahl1: {
      von: '1980-01',
      bis: `${new Date().getFullYear()}-${this.months[new Date().getMonth()]}`,
      min: '1980-01',
    },
    rbGrafikAuswahl2: {
      von: '1993-01',
      bis: `${new Date().getFullYear()}-${this.months[new Date().getMonth()]}`,
      min: '1993-01',
    },
    rbGrafikAuswahl3: {
      von: '2010-09',
      bis: `${new Date().getFullYear()}-${this.months[new Date().getMonth()]}`,
      min: '2010-09',
    },
    goldpreis: {
      von: '1994-09',
      bis: `${new Date().getFullYear()}-${this.months[new Date().getMonth()]}`,
      min: '1994-09',
    },
    rbGrafikAuswahl5: {
      von: '2004-01',
      bis: `${new Date().getFullYear()}-${this.months[new Date().getMonth()]}`,
      min: '2004-01',
    },
  };
  defaultParamsSpecialChart = {
    chartType: 'special',
    series: {
      zins1: {
        chartType: 'line',
      },
      zins2: {
        chartType: 'line',
      },
      zins3: {
        chartType: 'line',
      },
    },
    showLogo: false,
    colors: {
      zins1: 1,
      zins2: 2,
      zins3: 3,
    },
    differentialValue: false,
    annotation: false,
    split: false,
    von: this.pickerDates.rbGrafikAuswahl1.von,
    bis: this.pickerDates.rbGrafikAuswahl1.bis,
    selectedSpecialValue: 'rbGrafikAuswahl1',
    selectedSpecialSubValue: ['effektivzins_10'],
  };
  paramsSpecialChart = { ...this.defaultParamsSpecialChart };
  defaultSelectedSpecialValue = 'rbGrafikAuswahl1';
  defaultSelectedSpecialSubValue = {
    rbGrafikAuswahl1: ['effektivzins_10'],
    rbGrafikAuswahl2: ['bs_darlehenszins', 'bs_baugeld_10'],
    rbGrafikAuswahl3: ['fd_aufschlag_24'],
    rbGrafikAuswahl5: ['hp_darlehenhoehe'],
  };
  selectedSpecialValue = 'rbGrafikAuswahl1';
  selectedSpecialSubValue = {
    rbGrafikAuswahl1: ['effektivzins_10'],
    rbGrafikAuswahl2: ['bs_darlehenszins', 'bs_baugeld_10'],
    rbGrafikAuswahl3: ['fd_aufschlag_24'],
    rbGrafikAuswahl5: ['hp_darlehenhoehe'],
  };
  specialChartData: IChartData = {
    id: 'line-chart-3',
    show: false,
    params: {},
    data: [],
  };
  data = [];
  objSpecialJsonB64 = btoa(
    JSON.stringify({
      params: this.paramsSpecialChart,
    })
  );

  //base chart variables
  selectedOptions: string[] = ['showLogo'];
  params: ChartGeneratorParams = {
    chartType: 'base',
    differentialValue: false,
    annotation: false,
    split: false,
    showLogo: true,
    von: `${new Date().getFullYear() - 10}-01`,
    bis: `${new Date().getFullYear()}-${this.months[new Date().getMonth()]}`,
    colors: {
      zins1: 1,
      zins2: 2,
      zins3: 3,
    },
    series: {
      zins1: {
        value: 'hypothekenzinsen',
        subValue: 'bg_10',
        chartType: 'line',
      },
      zins2: {
        value: 'tagesgeld',
        subValue: 'tg_basis',
        chartType: 'line',
      },
      zins3: {
        value: '',
        subValue: '',
        chartType: 'line',
      },
    },
  };
  defaultParams = { ...this.params };
  alert = {
    text: this.$t('warnings.zinsChartWarning'),
    show: false,
  };
  chartData1: IChartData = {
    id: 'line-chart-1',
    show: false,
    params: {},
    data: [],
  };
  chartData2: IChartData = {
    id: 'line-chart-2',
    show: false,
    params: {},
    data: [],
  };
  chartData: Array<IBaseChart> = [];
  objJsonB64 = btoa(
    JSON.stringify({
      params: this.params,
    })
  );
  get validateHeight() {
    return (window as any).innerHeight < 980;
  }
  setBaseChartData!: MutationMethod;
  setLoading!: MutationMethod;
  setSnackBarData!: MutationMethod;
  saveZinsChartData!: (payload: RequiredPayload) => Promise<Object>;
  fetchInterestChartData!: (payload) => Promise<any>;

  @Watch('needUpdate') updateSpecialCharts() {
    this.specialChartData = {
      ...this.specialChartData,
      show: !!this.data.length,
      params: this.paramsSpecialChart,
      data: this.data || [],
    };

    this.needUpdate = false;
  }
  @Watch('loading')
  @Watch('drawer')
  updatePadding() {
    this.updateMainPadding();
    this.onScreenResize();
  }

  @Watch('$route', { immediate: true }) setDrawer() {
    if (this.drawerApp) {
      this.$emit('change-drawer', { drawer: false, mini: true });
    }
  }
  @Watch('objJsonB64', { immediate: true, deep: true }) updateCharts() {
    this.updateData();
  }
  @Watch('isCopied') handleChangeCopied() {
    if (this.isCopied) {
      setTimeout(() => {
        this.isCopied = false;
      }, 2000);
    }
  }
  get defaultStyle() {
    const height = window.innerHeight - 278;
    return {
      'min-height': `${height}px`,
    };
  }

  onScreenResize() {
    const el = document.getElementById('chart-generator-drawer');
    if (el) {
      window.addEventListener('resize', () => {
        this.updateMainPadding();
      });
    }
  }
  updateMainPadding() {
    const width = document.getElementById(
      'chart-generator-drawer'
    )?.clientWidth;

    const main = (document as any).querySelector('.v-main');
    if (this.drawer) {
      main.style.paddingRight = `${width}px`;
    }
    if (!this.drawer) {
      main.style.paddingRight = '0px';
    }
  }
  handleChangeIframeDialog(val) {
    this.dialog = val;
  }
  onDrawerChanged() {
    if (this.drawerApp) {
      this.$emit('change-drawer', { drawer: false, mini: true });
    }
    this.drawer = !this.drawer;
  }
  onClose() {
    this.dialog = false;
    this.isCopied = false;
    this.iframe = '';
  }
  async handleChangeDialog(objJsonB64, chartData) {
    try {
      const data: any = await this.saveZinsChartData({ data: chartData });
      if (data?.data?.slug && objJsonB64) {
        const src =
          process.env.NODE_ENV !== 'production'
            ? `http://localhost:8080/rate-chart/${data.data.slug}/?params=${objJsonB64}`
            : `${HOST}/rate-chart/${data.data.slug}/?params=${objJsonB64}`;
        this.iframe = `<iframe \n  src="${src}" \n width="100%" \n height="900px" \n>
</iframe>`;
        this.dialog = true;
      }
      this.setLoading(false);
    } catch (e) {
      this.setSnackBarData({
        text: 'Bei der API Anfrage trat ein Fehler auf',
        show: true,
        timeout: 3000,
      });
      this.setLoading(false);
    }
  }

  //special chart methods
  resetSpecialParams() {
    this.selectedSpecialValue = this.defaultSelectedSpecialValue;
    this.selectedSpecialSubValue = this.defaultSelectedSpecialSubValue;
    this.isShowLogo = true;
    this.paramsSpecialChart = { ...this.defaultParamsSpecialChart };
    const objJsonStr = JSON.stringify({
      params: this.defaultParamsSpecialChart,
    });
    this.objSpecialJsonB64 = btoa(objJsonStr);
    this.specialChartData = {
      id: 'line-chart-2',
      show: false,
      params: {},
      data: [],
    };
    this.data = [];
  }
  handleChangeSubValues(val) {
    this.selectedSpecialSubValue = { ...this.selectedSpecialSubValue, ...val };
  }
  onSpecialParamsUpdated(val) {
    this.paramsSpecialChart = val;
  }
  handleChangeShowLogo(val) {
    this.isShowLogo = val;
  }
  handleChangeSelectedSpecialValue(val) {
    this.selectedSpecialValue = val;
  }
  async fetchSpecialChartData() {
    const payload = {};
    const params: string[] = [];
    const fields: string[] = [];
    const products: string[] = [];

    this.paramsSpecialChart = {
      ...this.paramsSpecialChart,
      selectedSpecialValue: this.selectedSpecialValue,
      selectedSpecialSubValue:
        this.selectedSpecialSubValue[this.selectedSpecialValue],
    };
    if (this.selectedSpecialSubValue[this.selectedSpecialValue]) {
      const items = SPECIAL_CHART_FORM_SUB_OPTIONS[
        this.paramsSpecialChart.selectedSpecialValue
      ].map((i) => i);
      const values: string[] = [];
      items.forEach((i) => {
        if (this.paramsSpecialChart.selectedSpecialSubValue.includes(i.value)) {
          if (i.additionalValue) {
            values.push(i.additionalValue.value);
          }
          if (i.additionalValue2) {
            values.push(i.additionalValue2.value);
          }
          values.push(i.value);
        }
      });
      this.paramsSpecialChart = {
        ...this.paramsSpecialChart,
        selectedSpecialSubValue: values,
      };
      values.forEach((product) => {
        products.push(`"${product}"`);
      });
    } else {
      products.push('"goldpreis"');
    }

    params.push(`zeitraumVon: "${this.paramsSpecialChart.von}-01"`);
    params.push(`zeitraumBis: "${this.paramsSpecialChart.bis}-01"`);
    params.push(`produkte: [${products}]`);

    Object.assign(payload, { params, fields, chartType: 'special' });
    this.paramsSpecialChart = {
      ...this.paramsSpecialChart,
      showLogo: this.isShowLogo,
    };
    this.data = await this.fetchInterestChartData(payload);

    const objJsonStr = JSON.stringify({
      params: this.paramsSpecialChart,
    });
    this.objSpecialJsonB64 = btoa(objJsonStr);
    this.needUpdate = true;
  }

  //base chart methods
  updateData() {
    if (this.params.split && !this.params.differentialValue) {
      const zins1 = this.chartData
        .filter((item) => item?.zins1)
        .map((item) => item?.zins1 || 0);
      const zins2 = this.chartData
        .filter((item) => item?.zins2)
        .map((item) => item?.zins2 || 0);
      const zins3 = this.chartData
        .filter((item) => item?.zins3)
        .map((item) => item?.zins3 || 0);
      const max1 = Math.max(...zins1);
      const max2 = Math.max(...zins2);
      const max3 = Math.max(...zins3);
      const arr = [max1, max2, max3].filter((item) => item);
      const o = {
        zins1: max1,
        zins2: max2,
        zins3: max3,
      };

      const searchMax = Object.keys(o).find(
        (key) => Math.max(...arr) === o[key]
      );
      const chartData1 = this.chartData.map((item) => {
        if (searchMax === 'zins1') {
          return {
            ...item,
            annotation: null,
            zins2: null,
            zins3: null,
          };
        }
        if (searchMax === 'zins2') {
          return {
            ...item,
            annotation: null,
            zins1: null,
            zins3: null,
          };
        }
        if (searchMax === 'zins3') {
          return {
            ...item,
            annotation: null,
            zins1: null,
            zins2: null,
          };
        }
      });
      const chartData2 = this.chartData.map((item) => {
        if (searchMax === 'zins1') {
          return {
            ...item,
            zins1: null,
          };
        }
        if (searchMax === 'zins2') {
          return {
            ...item,
            zins2: null,
          };
        }
        if (searchMax === 'zins3') {
          return {
            ...item,
            zins3: null,
          };
        }
      });
      //@ts-ignore
      //eslint-disable-next-line
      this.chartData1 = { ...this.chartData1, show: true, params: this.params, data: chartData1 || [] };
      //@ts-ignore
      //eslint-disable-next-line
      this.chartData2 = { ...this.chartData2, show: true, params: this.params, data: chartData2 || [] };
    }
    if (this.params.differentialValue) {
      let minZins = '';
      this.chartData.forEach((item) => {
        const { zins1, zins2 } = item;
        if (Number(zins1) !== Number(zins2)) {
          const min = Math.min(...[Number(zins1), Number(zins2)]);
          const search = Object.keys(item).find((key) => item[key] === min);
          if (!minZins && search) {
            minZins = search;
          }
        }
      });
      if (minZins) {
        const chartDataDifference = this.chartData.map((item) => {
          const { zins1, zins2, ...rest } = item;
          if (minZins === 'zins1') {
            const result = (zins1 || 0) - (zins2 || 0);
            return {
              ...rest,
              zins1: result,
            };
          }
          if (minZins === 'zins2') {
            const result = (zins2 || 0) - (zins1 || 0);
            return {
              ...rest,
              zins1: result,
            };
          }
        });

        this.chartData1 = {
          ...this.chartData1,
          show: false,
          params: this.params,
          data: [],
        };
        //@ts-ignore
        //eslint-disable-next-line
        this.chartData2 = { ...this.chartData2, show: true, params: this.params, data: chartDataDifference || [] };
      } else {
        const chartDataDifference = this.chartData.map((item) => {
          const { zins1, zins2, ...rest } = item;
          return {
            ...rest,
            zins1,
          };
        });
        //@ts-ignore
        //eslint-disable-next-line
        this.chartData1 = { ...this.chartData1, show: false, params: this.params, data:  [] };
        //@ts-ignore
        //eslint-disable-next-line
        this.chartData2 = { ...this.chartData2, show: true, params: this.params, data: chartDataDifference || [] };
      }
    }
    if (!this.params.split && !this.params.differentialValue) {
      this.chartData1 = {
        id: 'line-chart-1',
        show: false,
        params: {},
        data: [],
      };
      this.chartData2 = {
        ...this.chartData2,
        show: !!this.chartData.length,
        params: this.params,
        data: this.chartData || [],
      };
    }
  }
  updateDataAnnotations(val) {
    this.chartData = this.chartData.map((item) => {
      if (typeof item.annotation === 'number') {
        return { ...item, annotation: val };
      } else {
        return item;
      }
    });
    this.updateData();
  }
  resetParams() {
    this.params = this.defaultParams;
    this.selectedOptions = ['showLogo'];
    this.setBaseChartData([]);
    const objJsonStr = JSON.stringify({
      params: this.defaultParams,
    });
    this.objJsonB64 = btoa(objJsonStr);
    this.chartData = [];
    this.chartData1 = {
      ...this.chartData1,
      data: [],
      show: false,
    };
    this.chartData2 = {
      ...this.chartData2,
      data: [],
      show: false,
    };
  }
  async fetchBaseData() {
    //@ts-ignore
    if (this.$refs.form.validate()) {
      const payload = {};
      const params: string[] = [];
      const fields: string[] = [];
      const products: string[] = [];
      const errors: boolean[] = [];
      const values: number[] = [];
      const orderValues = {};
      if (
        this.selectedOptions.includes('annotation') &&
        !this.selectedOptions.includes('differentialValue')
      ) {
        fields.push('annotation');
        fields.push('hint');
      }
      params.push(`zeitraumVon: "${this.params.von}-01"`);
      params.push(`zeitraumBis: "${this.params.bis}-01"`);

      Object.keys(this.params.series).forEach((key) => {
        if (
          !this.params.series[key].value &&
          !this.params.series[key].subValue
        ) {
          errors.push(true);
        }
        if (
          this.params.series[key].value &&
          !this.params.series[key].subValue
        ) {
          products.push(`"${this.params.series[key].value}"`);
          values.push(this.params.series[key].value);
        }
        if (this.params.series[key].value && this.params.series[key].subValue) {
          products.push(`"${this.params.series[key].subValue}"`);
          values.push(this.params.series[key].subValue);
        }
      });
      values.forEach((val, index) => {
        Object.assign(orderValues, { [val]: index });
      });

      params.push(`produkte: [${products}]`);
      Object.assign(payload, { params, fields, chartType: 'base' });

      if (errors.length !== 3) {
        this.chartData = await this.fetchInterestChartData(payload);
        const seriesValues: object[] = [];
        const emptySeries: object[] = [];
        const seriesWithValue: object[] = [];
        const result = {};

        Object.keys(this.params.series).forEach((key) => {
          if (
            this.params.series[key].value &&
            this.params.series[key].subValue
          ) {
            seriesValues.push({ [key]: this.params.series[key] });
          }
        });
        Object.keys(this.params.series).forEach((key) => {
          if (
            this.params.series[key].value &&
            !this.params.series[key].subValue
          ) {
            seriesValues.push({ [key]: this.params.series[key] });
          }
        });
        Object.keys(this.params.series).forEach((key) => {
          if (
            !this.params.series[key].value &&
            !this.params.series[key].subValue
          ) {
            seriesValues.push({ [key]: this.params.series[key] });
          }
        });
        seriesValues.forEach((i: any) => {
          const [[key]] = Object.entries(i);
          if (!i[key].value && !i[key].subValue) {
            emptySeries.push(i);
          }
          if (i[key].value || i[key].subValue) {
            seriesWithValue.push(i);
          }
        });

        seriesWithValue.sort((a: any, b: any) => {
          const [[keyA]] = Object.entries(a);
          const [[keyB]] = Object.entries(b);
          const valueA = a[keyA].subValue || a[keyA].value;
          const valueB = b[keyB].subValue || b[keyB].value;
          if (orderValues[valueA] === orderValues[valueB]) {
            return 0;
          }
          return orderValues[valueA] < orderValues[valueB] ? -1 : 1;
        });

        seriesWithValue.concat(emptySeries).forEach((item, index) => {
          const [[, val]] = Object.entries(item);
          Object.assign(result, { [`zins${index + 1}`]: val });
        });
        const params = {
          series: result as ResultSeries,
          colors: this.params.colors,
          von: this.params.von,
          bis: this.params.bis,
          differentialValue: this.selectedOptions.includes('differentialValue'),
          annotation: this.selectedOptions.includes('annotation'),
          split: this.selectedOptions.includes('split'),
          showLogo: this.selectedOptions.includes('showLogo'),
          chartType: 'base',
        };
        this.params = params;
        const objJsonStr = JSON.stringify({
          params: params,
        });
        this.objJsonB64 = btoa(objJsonStr);
      } else {
        this.alert = {
          ...this.alert,
          show: true,
        };
        setTimeout(() => {
          this.alert = {
            ...this.alert,
            show: false,
          };
        }, 2000);
      }
    }
  }
  onParamsUpdated(val) {
    this.params = val;
  }
  handleChangeSelectedValue(val) {
    this.selectedOptions = val;
  }
  beforeDestroy() {
    window.removeEventListener('resize', () => {});
  }
}
