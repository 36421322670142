












































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import FormBaseChart from '@/views/ChartGenerator/tabs/FormBaseChart/FormBaseChart.vue';
import LineChart from '@/components/fmh_components/common/LineChart/LineChart.vue';
import Alert from '@/components/fmh_components/common/Alert/Alert.vue';

import { BASE_BLACK_COLOR } from '@/utils/constants';
import {
  IBaseChart,
  IChartData,
  ChartGeneratorParams,
  IUser,
} from '@/interfaces';

@Component({
  components: { FormBaseChart, LineChart, Alert },
  computed: {
    ...mapGetters({
      loading: 'interestCharts/loading',
      user: 'auth/getUser',
    }),
  },
})
export default class BaseChart extends Vue {
  @Prop() handleChangeDialog!: Function;
  @Prop() fetchBaseData!: Function;
  @Prop() alert!: Object;
  @Prop() chartData1!: IChartData;
  @Prop() chartData2!: IChartData;
  @Prop() chartData!: Array<IBaseChart>;
  @Prop() objJsonB64!: String;
  @Prop() params!: ChartGeneratorParams;

  user?: IUser;
  color = BASE_BLACK_COLOR;

  get isDemoUser() {
    return !!this.user?.isDemoUser;
  }
  get validate() {
    return this.chartData1.data.length || this.chartData2.data.length;
  }

  updateDataAnnotations(val) {
    this.$emit('updateDataAnnotationsBase', val);
  }

  downloadDoc() {
    //eslint-disable-next-line
    const HtmlHead = "<html lang='de'><head><meta charset='utf-8'><title>Doc</title></head><body>";
    const EndHtml = '</body></html>';
    const canvas1 = document.getElementById('line-chart-1');
    const canvas2 = document.getElementById('line-chart-2');
    const dataURL = (canvas1 as HTMLCanvasElement)?.toDataURL() || '';
    const dataURL2 = (canvas2 as HTMLCanvasElement)?.toDataURL() || '';
    //eslint-disable-next-line
    const image = "<div id='export'><img alt='canvas image' class='hide' src='" + dataURL +"'/></div>";
    //eslint-disable-next-line
    const image2 = "<div id='export'><img alt='canvas image' class='hide' src='" + dataURL2 +"'/></div>";
    const html = HtmlHead + image2 + image + EndHtml;
    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    const url =
      'data:application/msword;charset=utf-8,' + encodeURIComponent(html);

    // Specify file name
    const filename = 'document.doc';

    // Create download link element
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    if ((navigator as any).msSaveOrOpenBlob) {
      (navigator as any).msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;
      // Setting the file name
      downloadLink.download = filename;
      //triggering the function
      downloadLink.click();
    }
    document.body.removeChild(downloadLink);
  }
  mounted() {
    this.fetchBaseData();
  }
}
