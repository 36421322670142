











































































































































































































































































































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

import DatePicker from '@/views/ChartGenerator/common/DatePicker.vue';

import {
  BASE_BLACK_COLOR,
  BASE_COLOR,
  CHART_GENERATOR_DETAIL_FORM_HINTS,
  INTEREST_OPTIONS,
  SELECTED_COLOR,
  SUB_INTEREST_OPTIONS,
} from '@/utils/constants';
import { ChartGeneratorParams } from '@/interfaces';

@Component({
  components: { DatePicker },
})
export default class FormBaseChart extends Vue {
  @Prop() params!: ChartGeneratorParams;
  @Prop() selectedOptions!: Array<string>;
  @Prop() updateParams!: Function;
  @Prop() resetParams!: Function;
  @Prop() handleChangeDialog!: Function;

  min = '1995-01-01';
  selectedColor = SELECTED_COLOR;
  color = BASE_BLACK_COLOR;
  baseColor = BASE_COLOR;
  interestOptions = INTEREST_OPTIONS;
  subInterestOptions = SUB_INTEREST_OPTIONS;
  options = [
    { text: 'Differenzwert', value: 'differentialValue' },
    { text: 'Annotationen', value: 'annotation' },
    { text: 'FMH X Logo einblenden', value: 'showLogo' },
  ];
  hints = CHART_GENERATOR_DETAIL_FORM_HINTS;
  rules = {
    1: [(v) => !!v || 'Eingabe erforderlich!'],
    2: [(v) => !!v || 'Eingabe erforderlich!'],
    3: [(v) => !!v || 'Eingabe erforderlich!'],
  };
  get colorModelSource() {
    return {
      zins1: this.params.colors.zins1,
      zins2: this.params.colors.zins2,
      zins3: this.params.colors.zins3,
    };
  }
  get icon() {
    return {
      zins1:
        this.params.series.zins1.chartType === 'area'
          ? 'mdi-checkbox-blank'
          : 'mdi-minus',
      zins2:
        this.params.series.zins2.chartType === 'area'
          ? 'mdi-checkbox-blank'
          : 'mdi-minus',
      zins3:
        this.params.series.zins3.chartType === 'area'
          ? 'mdi-checkbox-blank'
          : 'mdi-minus',
    };
  }
  get chartColors() {
    return this.$store.state.chartColors.chartColors;
  }
  get selectedOptionsValue() {
    return this.selectedOptions;
  }
  set selectedOptionsValue(values) {
    this.$emit('changeSelectedOptions', values);
  }

  get model() {
    return {
      1: this.params.series.zins1.value,
      2: this.params.series.zins2.value,
      3: this.params.series.zins3.value,
    };
  }
  get subModel() {
    return {
      1: this.params.series.zins1.subValue,
      2: this.params.series.zins2.subValue,
      3: this.params.series.zins3.subValue,
    };
  }
  get chartTypeModel() {
    return {
      1: this.params.series.zins1.chartType,
      2: this.params.series.zins2.chartType,
      3: this.params.series.zins3.chartType,
    };
  }
  get validateTypColor() {
    return {
      1: this.model[1] || false,
      2: this.model[2] || false,
      3: this.model[3] || false,
    };
  }
  get validate() {
    return {
      1:
        (this.model[1] && this.subInterestOptions[this.model[1]]?.length) ||
        false,
      2:
        (this.model[2] && this.subInterestOptions[this.model[2]]?.length) ||
        false,
      3:
        (this.model[3] && this.subInterestOptions[this.model[3]]?.length) ||
        false,
    };
  }

  validateCheckboxes(option) {
    if (option.value === 'split') {
      return true;
    }
    if (option.value === 'differentialValue') {
      const errors: boolean[] = [];
      Object.keys(this.params.series).forEach((key) => {
        if (
          this.params.series[key].value &&
          this.subInterestOptions[this.params.series[key]?.value]?.length &&
          !this.params.series[key].subValue
        ) {
          errors.push(true);
        }
        if (!this.params.series[key]?.value) {
          errors.push(true);
        }
      });
      if (errors.length != 1) {
        if (this.selectedOptionsValue.includes('differentialValue')) {
          const values = (this.selectedOptionsValue || []).filter(
            (item) => item !== 'differentialValue'
          );
          this.$emit('changeSelectedOptions', values);
        }
        return true;
      }
    }
    return false;
  }
  handleSeriesChanged(key, value, path) {
    const o = { 1: 'zins1', 2: 'zins2', 3: 'zins3' };
    let updated = {};
    if (path === 'value') {
      updated = {
        ...this.params,
        series: {
          ...this.params.series,
          [o[key]]: {
            ...this.params.series[o[key]],
            subValue: '',
            [path]: value,
          },
        },
      };
    } else {
      updated = {
        ...this.params,
        series: {
          ...this.params.series,
          [o[key]]: {
            ...this.params.series[o[key]],
            [path]: value,
          },
        },
      };
    }
    this.$emit('handleUpdateParams', updated);
  }
  handleChangeDate(value) {
    const [[key, val]] = Object.entries(value);
    const updated = { ...this.params, [key]: val };
    this.$emit('handleUpdateParams', updated);
  }
  handleColorChanged(e, key) {
    const updated = {
      ...this.params,
      colors: { ...this.params.colors, [key]: e },
    };
    this.$emit('handleUpdateParams', updated);
  }
}
