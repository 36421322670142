

































































































































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import DatePicker from '@/views/ChartGenerator/common/DatePicker.vue';

import {
  BASE_BLACK_COLOR,
  BASE_COLOR,
  CHART_GENERATOR_DETAIL_FORM_HINTS,
  SELECTED_COLOR,
  SPECIAL_CHART_FORM_OPTIONS,
  SPECIAL_CHART_FORM_SUB_OPTIONS,
} from '@/utils/constants';

import { ISpecialChart } from '@/interfaces';

@Component({
  components: { DatePicker },
})
export default class FormSpecialChart extends Vue {
  @Prop() selectedSpecialValue!: string;
  @Prop() selectedSpecialSubValue!: object;
  @Prop() params!: ISpecialChart;
  @Prop() pickerDates!: object;
  @Prop() isShowLogo!: boolean;

  selectedColor = SELECTED_COLOR;
  color = BASE_BLACK_COLOR;
  baseColor = BASE_COLOR;
  specialOptions = SPECIAL_CHART_FORM_OPTIONS;
  subOptions = SPECIAL_CHART_FORM_SUB_OPTIONS;
  hints = CHART_GENERATOR_DETAIL_FORM_HINTS;

  @Watch('selectedSpecialValue') setPickerDefault() {
    const von = this.pickerDates[this.selectedSpecialValue].von;
    const bis = this.pickerDates[this.selectedSpecialValue].bis;
    const updated = { ...this.params, von, bis };
    this.$emit('handleUpdateParams', updated);
  }
  get showLogo() {
    return this.isShowLogo;
  }
  set showLogo(value) {
    this.$emit('changeShowLogo', value);
  }
  get chartTypeModel() {
    return {
      1: this.params.series.zins1.chartType,
      2: this.params.series.zins2.chartType,
      3: this.params.series.zins3.chartType,
    };
  }
  get icon() {
    return {
      zins1:
        this.params.series.zins1.chartType === 'area'
          ? 'mdi-checkbox-blank'
          : 'mdi-minus',
      zins2:
        this.params.series.zins2.chartType === 'area'
          ? 'mdi-checkbox-blank'
          : 'mdi-minus',
      zins3:
        this.params.series.zins3.chartType === 'area'
          ? 'mdi-checkbox-blank'
          : 'mdi-minus',
    };
  }
  get chartColors() {
    return this.$store.state.chartColors.chartColors;
  }

  get model() {
    return this.selectedSpecialValue;
  }
  set model(val) {
    this.$emit('changeValue', val);
  }
  get modelSub() {
    return this.selectedSpecialSubValue[this.selectedSpecialValue];
  }
  set modelSub(val) {
    this.$emit('changeSubValues', { [this.selectedSpecialValue]: val });
  }

  get colorModelSource() {
    return {
      zins1: this.params.colors.zins1,
      zins2: this.params.colors.zins2,
      zins3: this.params.colors.zins3,
    };
  }
  isShowAdditionalFeatures(option) {
    return this.selectedSpecialSubValue[this.selectedSpecialValue].includes(
      option.value
    );
  }
  findIndex(option) {
    const index = this.subOptions[this.selectedSpecialValue]
      .filter((item) =>
        this.selectedSpecialSubValue[this.selectedSpecialValue].includes(
          item.value
        )
      )
      .findIndex((val) => val.value === option.value);
    return index + 1;
  }
  handleChangeDate(value) {
    const [[key, val]] = Object.entries(value);
    const updated = { ...this.params, [key]: val };
    this.$emit('handleUpdateParams', updated);
  }

  handleColorChanged(e, key) {
    const updated = {
      ...this.params,
      colors: { ...this.params.colors, [key]: e },
    };
    this.$emit('handleUpdateParams', updated);
  }
  handleSeriesChanged(key, value) {
    this.$emit('handleUpdateParams', {
      ...this.params,
      series: { ...this.params.series, [key]: { chartType: value } },
    });
  }
}
